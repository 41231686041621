import React from 'react';
import './styles/globals.scss';
import './styles/landing.scss';
import './styles/nav.scss';
import './styles/mint.scss';
import './styles/main.scss';
import './styles/partners.scss';
import './styles/roadmap.scss';
import './styles/footer.scss';
import './styles/community.scss';
import {
  BrowserRouter as Router, Route, Switch, NavLink,
} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import Landing from './screens/landing';
import Main from './screens/main';

library.add(far);

const FallBack = (props) => {
  return (
    <div id="fallbackcontainer">
      <div id="fallbacktext">URL Not Found</div>
      <NavLink id="fallbackbutton" to="/">Go Back Home</NavLink>
    </div>
  );
};

const App = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/landing" component={Landing} />
        <Route exact path="/" component={Main} />
        <Route component={FallBack} />
      </Switch>
    </Router>
  );
};

export default App;
