import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faDiscord, faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import Img from 'react-cool-img';
import Footer1 from '../files/footer1.jpg';
import Footer2 from '../files/footer2.jpg';
import Footer3 from '../files/footer3.jpg';
import Footer4 from '../files/footer4.jpg';
import Footer5 from '../files/footer5.jpg';

const partner = (props) => {
  return (
    <div id="footercontainer">
      <div id="footerimages">
        <div id="imggrad" />
        <div id="imggrad2" />
        <Img
          id="fi1"
          src={Footer1}
        />
        <Img
          id="fi2"
          src={Footer2}
        />
        <Img
          id="fi3"
          src={Footer3}
        />
        <Img
          id="fi4"
          src={Footer4}
        />
        <Img
          id="fi5"
          src={Footer5}
        />
      </div>
      <div id="footerc2">
        <div id="footerlogo" />
        <div id="footerline" />
        <div id="copysocials">
          <div id="Fcopyright">Copyright © 2021 BossDrops. All rights reserved.</div>
          <div id="Fsocialcontainer">
            <a className="footerlink" href="https://discord.gg/MQeGJgSKNq" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDiscord} size="1x" />
            </a>
            <a className="footerlink" href="https://twitter.com/Immortal10k" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="1x" />
            </a>
            <a className="footerlink" href="https://www.instagram.com/immortal10k/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default partner;
