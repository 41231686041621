/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useCallback, useState } from 'react';
import Web3Modal from 'web3modal';
import { connect } from 'react-redux';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { useMediaQuery } from 'react-responsive';
import { NavHashLink } from 'react-router-hash-link';
import WalletLink from 'walletlink';
import { providers } from 'ethers';
import { slide as Menu } from 'react-burger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faDiscord, faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { setprovider, disconnect } from '../actions/index';
import ellipseAddress from '../lib/utilities';

const INFURA_ID = '7e40f3eda5f949dc820627f0f2b1e158';

const providerOptions = {
  // Example with injected providers
  injected: {
    display: {
      // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
      name: 'INJECTED',
      description: 'CONNECT WITH THE PROVIDER IN YOUR BROWSER',
    },
    package: null,
  },
  // Example with WalletConnect provider
  walletconnect: {
    display: {
      // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
      name: 'MOBILE',
      description: 'SCAN QRCODE WITH YOUR MOBILE WALLET',
    },
    package: WalletConnectProvider,
    options: {
      infuraId: INFURA_ID,
    },
  },
  'custom-walletlink': {
    display: {
      logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
      name: 'COINBASE',
      description: 'CONNECT TO COINBASE WALLET (NOT COINBASE APP)',
    },
    options: {
      appName: 'Coinbase', // Your app name
      networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_, options) => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({
        appName,
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },
};

const Nav = (props) => {
  const isNotMobile = useMediaQuery({ query: '(min-width: 701px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' });
  const [open, setOpen] = useState(false);
  const web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  });
  // eslint-disable-next-line no-unused-vars

  const connect = async () => {
    await web3Modal.clearCachedProvider();
    const provider = await web3Modal.connect();

    // We plug the initial `provider` into ethers.js and get back
    // a Web3Provider. This will add on methods from ethers.js and
    // event listeners such as `.on()` will be different.
    const web3Provider = new providers.Web3Provider(provider);

    const signer = web3Provider.getSigner();

    const address = await signer.getAddress();

    const network = await web3Provider.getNetwork();

    props.setprovider(
      {
        provider,
        address,
        web3Provider,
        chainId: network.chainId,
      },
    );
  };

  const disconnect = useCallback(
    async () => {
      await web3Modal.clearCachedProvider();
      if (props.provider?.disconnect && typeof props.provider.disconnect === 'function') {
        await props.provider.disconnect();
      }
      props.disconnect();
    },
    [props.provider],
  );

  //   // eslint-disable-next-line consistent-return
  //   useEffect(() => {
  //     if (props.provider?.on) {
  //       const handleAccountsChanged = (accounts) => {
  //         // eslint-disable-next-line no-console
  //         console.log('accountsChanged', accounts);
  //         dispatch({
  //           type: 'SET_ADDRESS',
  //           address: accounts[0],
  //         });
  //       };

  //       const handleChainChanged = (accounts) => {
  //         // eslint-disable-next-line no-console
  //         console.log('accountsChanged', accounts);
  //         dispatch({
  //           type: 'SET_ADDRESS',
  //           address: accounts[0],
  //         });
  //       };

  //       const handleDisconnect = (error) => {
  //         // eslint-disable-next-line no-console
  //         console.log('disconnect', error);
  //         disconnect();
  //       };

  //       props.provider.on('accountsChanged', handleAccountsChanged);
  //       props.provider.on('chainChanged', handleChainChanged);
  //       props.provider.on('disconnect', handleDisconnect);

  //       // Subscription Cleanup
  //       return () => {
  //         if (props.provider.removeListener) {
  //           props.provider.removeListener('accountsChanged', handleAccountsChanged);
  //           props.provider.removeListener('chainChanged', handleChainChanged);
  //           props.provider.removeListener('disconnect', handleDisconnect);
  //         }
  //       };
  //     }
  //   }, [props.provider, disconnect]);

  return (
    <nav>
      {isNotMobile && (
        <div id="navcontainer">
          <ul id="navulleft">
            <li className="navli">
              <NavHashLink
                smooth
                to="/#mains"
                activeClassName="selected"
              >Home
              </NavHashLink>
            </li>
            <li className="navli">
              <NavHashLink
                smooth
                to="/#legend"
                activeClassName="selected"
              >About Us
              </NavHashLink>
            </li>
            <li className="navli">
              <NavHashLink
                smooth
                to="/#roadmap"
                activeClassName="selected"
              >Roadmap
              </NavHashLink>
            </li>
            <li className="navli">
              <NavHashLink
                smooth
                to="/#partners"
                activeClassName="selected"
              >Partners
              </NavHashLink>
            </li>
          </ul>
          <ul id="navulright">
            <li className="navli">
              <a href="https://discord.gg/MQeGJgSKNq" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faDiscord} size="1x" />
              </a>
            </li>
            <li className="navli">
              <a href="https://twitter.com/Immortal10k" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} size="1x" />
              </a>
            </li>
            <li className="navli">
              <a href="https://www.instagram.com/immortal10k/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="1x" />
              </a>
            </li>
            <li className="navli">{props.web3Provider ? (
              <div role="button" onClick={disconnect} className="walletbutton">
                {ellipseAddress(props.address)}
              </div>
            ) : (
              <div role="button" onClick={connect} className="walletbutton">
                Connect Wallet
              </div>
            )}
            </li>

          </ul>
        </div>
      )}
      {isMobile && (
      <Menu
        isOpen={open}
        right
        onStateChange={(state) => setOpen(state.isOpen)}
      >
        <ul id="navulright">
          <li className="navli">
            <NavHashLink
              onClick={() => setOpen(false)}
              smooth
              to="/#mains"
              activeClassName="selected"
            >Home
            </NavHashLink>
          </li>
          <li className="navli">
            <NavHashLink
              onClick={() => setOpen(false)}
              smooth
              to="/#legend"
              activeClassName="selected"
            >About Us
            </NavHashLink>
          </li>
          <li className="navli">
            <NavHashLink
              onClick={() => setOpen(false)}
              smooth
              to="/#roadmap"
              activeClassName="selected"
            >Roadmap
            </NavHashLink>
          </li>
          <li className="navli">
            <NavHashLink
              onClick={() => setOpen(false)}
              smooth
              to="/#partners"
              activeClassName="selected"
            >Partners
            </NavHashLink>
          </li>
          <li className="navli">
            <a id="mobiledisc" href="https://discord.gg/MQeGJgSKNq" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDiscord} size="1x" />
            </a>

            <a id="mobiletwit" href="https://twitter.com/Immortal10k" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="1x" />
            </a>
            <a href="https://www.instagram.com/immortal10k/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>
          </li>
          <li className="navli">{props.web3Provider ? (
            <div role="button" onClick={disconnect} className="walletbutton">
              {ellipseAddress(props.address)}
            </div>
          ) : (
            <div role="button" onClick={connect} className="walletbutton">
              CONNECT
            </div>
          )}
          </li>
        </ul>
      </Menu>
      )}
    </nav>
  );
};

const mapStateToProps = (state) => (
  {
    provider: state.web3.provider,
    web3Provider: state.web3.web3Provider,
    address: state.web3.address,
    chainId: state.web3.chainId,
  }
);

export default connect(mapStateToProps, { setprovider, disconnect })(Nav);
