/* eslint-disable max-len */
import React from 'react';

const discord = (props) => {
  return (
    <div className="Dcontainer">
      <div id="Dtoprow" className="Ptoprow">
        <div className="partnerleft" />
        <div id="Atitle" className="Ptitle">COMMUNITY</div>
        <div className="partnerright" />
      </div>
      <div id="Dtextwrapper">
        <div id="Dtoptext">
          Join our discord and be a part of the Immortals community.
        </div>
        <div id="Dmiddletext">
          We&apos;ve saved a seat for you.
        </div>
        <a id="Dbutton" href="https://discord.gg/MQeGJgSKNq" target="_blank" rel="noopener noreferrer">
          Join Discord Community
        </a>
      </div>
    </div>
  );
};

export default discord;
