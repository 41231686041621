/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

const partner = (props) => {
  return (
    <div className="Pcontainer">
      <div className="Ptoprow">
        <div className="partnerleft" />
        <div className="Ptitle">PARTNERS</div>
        <div className="partnerright" />
      </div>
      <div id="logowrapper">
        <a id="BLUpartner" href="https://bossdrops.io/" target="_blank" rel="noopener noreferrer">
          <div id="BLUtext">Bosslogic Universe</div>
        </a>
        <a id="WWpartner" href="https://www.wilderworld.com/" target="_blank" rel="noopener noreferrer">
          <div id="WWtext">Wilder World</div>
        </a>

      </div>
    </div>
  );
};

export default partner;
