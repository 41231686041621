/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Arch from '../files/thearch.png';
import Sefr from '../files/Sefr.png';
import Ascend from '../files/theascend.png';
import Sazan from '../files/Sazan.png';
import Araxa from '../files/Araxa.png';

const carousel = (props) => {
  const [index, setI] = useState(4);

  const increment = () => {
    if ((index + 1) <= 9) {
      setI(index + 1);
    }
  };

  const decrement = () => {
    if ((index - 1) >= 0) {
      setI(index - 1);
    }
  };

  return (
    <div id="carouselcontainer">
      <Carousel
        className="Cleft"
        selectedItem={index}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        swipeable={false}
      >
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
      </Carousel>
      <Carousel
        className="Cright"
        selectedItem={index}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        swipeable={false}
      >
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
      </Carousel>
      <Carousel
        className="Ccenterleft"
        selectedItem={index}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        swipeable={false}
      >
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
      </Carousel>
      <Carousel
        className="Ccenterright"
        selectedItem={index}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        swipeable={false}
      >
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
      </Carousel>
      <div id="band" />
      <Carousel
        className="Ccenter"
        selectedItem={index}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        swipeable={false}
      >
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
        <div>
          <img src={Arch} />
        </div>
        <div>
          <img src={Ascend} />
        </div>
        <div>
          <img src={Sazan} />
        </div>
        <div>
          <img src={Araxa} />
        </div>
        <div>
          <img src={Sefr} />
        </div>
      </Carousel>
      <div id="carouselright" onClick={increment}><FontAwesomeIcon icon={['far', 'long-arrow-right']} size="1x" /></div>
      <div id="carouselleft" onClick={decrement}><FontAwesomeIcon icon={['far', 'long-arrow-left']} size="1x" /></div>
    </div>
  );
};

export default carousel;
