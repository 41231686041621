/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Img from 'react-cool-img';
import { useMediaQuery } from 'react-responsive';
import Nav from '../components/nav';
import MintButton from '../components/mintbutton';
import Partner from '../components/partnersection';
import Carousel from '../components/carousel';
import About from '../components/about';
import Aboutbg from '../files/aboutbg.jpg';
import Roadmap from '../files/roadmapdesk.png';
import RoadmapM from '../files/roadmapmobile.png';
import Footer from '../components/footer';
import Discord from '../components/discord';

const Main = (props) => {
  const isNotMobile = useMediaQuery({ query: '(min-width: 701px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' });
  return (
    <div id="maincontainer">
      <Nav />
      <div id="mainbg" />
      <div id="Mtopgradient" />
      <Img
        id="aboutbg"
        src={Aboutbg}
        style={{ backgroundColor: 'black', width: '300', height: '100' }}
      />
      <div id="Atopgradient" />
      <div id="Abottomgradient" />
      <section id="mains">

        <div>
          <MintButton />
          <Carousel />
        </div>
      </section>
      <section id="legend">
        <div>
          <About />
        </div>
      </section>
      <section id="roadmap">
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw',
        }}
        >
          {isNotMobile
          && (
          <Img
            id="roadmapbg"
            src={Roadmap}
            style={{ backgroundColor: 'black', width: '300', height: '100' }}
          />
          )}
          {isMobile
          && (
          <Img
            id="roadmapbg"
            src={RoadmapM}
          />
          )}
        </div>
      </section>
      <section id="partners">
        <Partner />
      </section>
      <section id="discord">
        <Discord />
      </section>
      <section id="footer">
        <Footer />
      </section>
    </div>
  );
};

export default Main;
