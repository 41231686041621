/* eslint-disable max-len */
/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ethers } from 'ethers';
// import { useMediaQuery } from 'react-responsive';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import abi from '../files/Immortals.json';

const IS_PROD = true;
const CONTRACT_ADDRESS = IS_PROD ? '0x3E4a79DA833bA1b844A90A352810437AEA0c49A6' : '0xeb256CFEc25f5343C9917e40495B4995467F694f';
const BD_API_URL = 'https://jcm439ppqa.execute-api.us-east-1.amazonaws.com';
// let serverlessresponse;
const WEI_PER_ETH = '1000000000000000000';
const WEI_PER_TOK_EA = '.07';
const WEI_PER_TOK_REG = '.15';

const customStyles = {
  content: {
    overflow: 'show',
    width: '270px',
    height: '350px',
    top: '50%',
    left: '50%',
    right: 'auto',
    backgroundImage: 'linear-gradient(0deg, rgba(0,0,0,0.9906337535014006) 0%, rgba(20,20,20,1) 20%, rgba(0,0,0,1) 100%)',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '5px solid #BAA577',
    color: '#B2A88E',
  },
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const mintbutton = (props) => {
  const [count, setCount] = useState(1);
  const [price, setPrice] = useState('.15');
  const [invalidcount, setInvalid] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalmessage, setMessage] = useState('');
  const [saleState, setSS] = useState(1);
  const [remaining, setRemaining] = useState(10000);
  const [early, setEarly] = useState(false);

  let contract;
  if (props.web3Provider) {
    try {
      contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        abi.abi,
        props.web3Provider.getSigner(),
      );
    } catch (err) {
      console.log(err);
      console.error('Error initializing contract');
    }
  }

  useEffect(async () => {
    while (true) {
      try {
        const info = await axios.get(`${BD_API_URL}/info${IS_PROD ? '' : '?testnet=1'}`);
        const sale = parseInt(info.data.saleState);
        const totalMinted = parseInt(info.data.totalMinted);
        setRemaining(10000 - totalMinted);
        setSS(sale);
      } catch (error) {
        console.log(error);
      }
      await delay(10 * 1000);
    }
  }, []);

  const openModal = async () => {
    if (!props.web3Provider) {
      setMessage('You must connect your wallet to the site in order to mint. Please use the connect button.');
    } else if (saleState === 1 || saleState === 2 || saleState === 3) {
      let response;
      try {
        response = await contract.canMint(props.address.toLowerCase());
      } catch (err) {
        setMessage('Error connecting to contract. Are you on the correct ethereum network?');
        console.log(err);
        return;
      }
      if (!response) {
        if (saleState === 1) {
          setMessage('You are not eligble for this early access period or have already minted on this wallet.');
        } else if (saleState === 2) {
          setMessage('You are not eligble for this early access period or have already minted on this wallet.');
        } else {
          setMessage('You are not eligble for this early access period or have already minted on this wallet.');
        }
      } else if (response) {
        setEarly(true);
        if (saleState === 1) {
          setMessage('Thank you for being a gauntlet holder. You may mint 1 Immortal at a reduced price.');
        } else if (saleState === 2) {
          setMessage('You are eligible for this early access period. You may mint 1 Immortal per slot at a reduced price.');
        } else {
          setMessage('You are eligible for this early access period. You may mint 1 Immortal per slot at a reduced price.');
        }
      }
    } else if (saleState === 4) {
      setEarly(true);
      setMessage('Public sale is live! Will you join the legion of 10,000?');
    } else {
      setMessage('Minting is not available yet. Refer to the details section for dates.');
    }
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const afterOpenModal = async () => {
    // references are now sync'd and can be accessed.
  };

  const mint = async () => {
    const helperScale = 1000000;
    let singlePriceWei, estimatedGas;
    if (saleState < 4 && saleState > 0) {
      singlePriceWei = ethers.BigNumber.from(WEI_PER_ETH).mul(Math.round(WEI_PER_TOK_EA * helperScale)).div(helperScale);
    } else {
      singlePriceWei = ethers.BigNumber.from(WEI_PER_ETH).mul(Math.round(WEI_PER_TOK_REG * helperScale)).div(helperScale);
    }
    const priceWei = singlePriceWei.mul(count);
    const responses = await contract.currentGauntletMints();
    console.log(responses);
    try {
      estimatedGas = await contract.estimateGas.mint(count, { value: priceWei });
    } catch (err) {
      alert('Error minting... Do you have enough funds in your wallet to cover the minting and gas fee?');
      return;
    }
    // console.log(estimatedGas);
    const gasLimit = estimatedGas.add(50000); // Add 50,000 to prevent failures from bad gas limit estimate.
    // eslint-disable-next-line one-var
    try {
      await contract.mint(count, { value: priceWei, gasLimit });
    } catch (err) {
      alert('There was an issue minting.');
      return;
    }
    setMessage('Thanks for minting!');
    setEarly(false);
  };

  const incrementCount = () => {
    if (saleState === 4 && count < 10) {
      if ((count + 1) > 0) {
        setInvalid(false);
      }
      setCount((count + 1));

      const pri = (count + 1) * 0.15;
      setPrice(pri.toString().slice(0, 4));
    } else if ((saleState === 1 || saleState === 2 || saleState === 3) && count < 1) {
      if ((count + 1) > 0) {
        setInvalid(false);
      }
      setCount((count + 1));
    }
  };

  const decrementCount = () => {
    if (count > 1) {
      if ((saleState === 1 || saleState === 2 || saleState === 3) && (count - 1) === 1) {
        setInvalid(false);
      }
      if (saleState === 4 && (count - 1) <= 10) {
        setInvalid(false);
      }
      setCount((count - 1));
      const pri = (count - 1) * 0.15;
      setPrice(pri.toString().slice(0, 4));
    }
  };

  return (
    <div id="mintwrapper">
      <div id="immlogo" />
      <div id="parallelogram">
        <div id="mintcontent">
          <div id="minttitle">IMMORTALS PUBLIC SALE</div>
          <div id="mintcols">
            <div id="mintcol1">
              <div id="remaining">{remaining} left out of 10,000</div>
              <div id="details"> Immortals will be minted in a randomly generated order. Images and properties will be revealed after all immortals have been minted</div>
              <div role="button" id="mintmodalopen" tabIndex="0" onClick={openModal}>
                Mint Immortals
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onAfterOpen={afterOpenModal}
        style={customStyles}
        ariaHideApp={false}
        overlayClassName="Overlay"
      >
        <div id="modalcontainer">
          <div id="modalclose" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </div>
          <div id="modalmessagetext">
            {modalmessage}
          </div>
          <div id="mintinput">{count}</div>
          <div className="countbutton" id="plus" onClick={incrementCount}>
            <FontAwesomeIcon icon={['far', 'plus']} size="lg" />
          </div>
          <div className="countbutton" id="minus" onClick={decrementCount}>
            <FontAwesomeIcon icon={['far', 'minus']} size="lg" />
          </div>
          {(!invalidcount) && (early) ? (
            <div role="button" id="mintreal" tabIndex={-1} onClick={mint} className="mintbutton">
              Mint: {price} ETH + gas
            </div>
          ) : (
            <div id="fakemint" className="mintbutton">
              Mint: {price} ETH + gas
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => (
  {
    provider: state.web3.provider,
    web3Provider: state.web3.web3Provider,
    address: state.web3.address,
    chainId: state.web3.chainId,
  }
);

export default connect(mapStateToProps, null)(mintbutton);
