/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { NavLink } from 'react-router-dom';

const Welcome = (props) => {
  const renderer = ({
    days, hours, minutes, seconds,
  }) => {
    return (
      <div id="Wcount">
        <div className="Wtimebox">
          <div className="Wtime">{zeroPad(days)}</div>
          <div className="Wtimetype">days</div>
        </div>
        <div className="Wtimebox">
          <div className="Wtime">{zeroPad(hours)}</div>
          <div className="Wtimetype">hours</div>
        </div>
        <div className="Wtimebox">
          <div className="Wtime">{zeroPad(minutes)}</div>
          <div className="Wtimetype">minutes</div>
        </div>
        <div className="Wtimebox">
          <div className="Wtime">{zeroPad(seconds)}</div>
          <div className="Wtimetype">seconds</div>
        </div>
      </div>
    );
  };

  return (
    <div id="welcomeimagecontainer">
      <div id="welcomecontainer">
        <div id="sparks" />
        <div id="logo" />
        <div id="Whashtag">#TheImmortals</div>
        <div id="Wdrop">DROPPING SOON</div>
        <Countdown date="2021-11-25T15:00:00" renderer={renderer} />
        <div id="Wsocials">
          <NavLink className="landingbutton" to="/#home">Enter Site</NavLink>
          <a id="Wdiscord" href="https://discord.gg/MQeGJgSKNq" target="_blank" rel="noopener noreferrer">Join our Discord</a>
          <a id="Wtwitter" href="https://twitter.com/Immortal10k" target="_blank" rel="noopener noreferrer">Follow our Twitter</a>
        </div>
      </div>
      <div id="azab">
        <div id="gradientleft" />
        <div id="gradientright" />
      </div>
    </div>
  );
};

export default Welcome;
