/* eslint-disable max-len */
import React from 'react';

const about = (props) => {
  return (
    <div className="Acontainer">
      <div className="Ptoprow">
        <div className="partnerleft" />
        <div id="Atitle" className="Ptitle">ABOUT US</div>
        <div className="partnerright" />
      </div>
      <div id="Atextwrapper">
        <div id="Atoptext">
          The world and her people were desperate. In what should have been a time of unity, came the greatest unrest and injustice. No modern arms could displace them, no army living left to fear… but perhaps, what was needed, had already come to pass.
          Tributes turned to prayer and prayer into flesh, memories breathed to life once more.
        </div>
        <div id="Amiddletext">
          Their bonds and guile, still unrivaled - a tenacious force that had tasted only victory through lifetimes of hardship. Dauntless in their demeanor and decisive in their approach, their mere visage was enough to send a chill through the ranks of those who would oppose them.
        </div>
        <div id="Abottomtext">The legendary army of 10,000 resurrected to walk the earth once again and shake the grounds on the path of war to an enemy that only they could stand in front of, to prove to all why they are the ones they call The Immortals.</div>
      </div>
    </div>
  );
};

export default about;
